<template>
  <div class="card" @click="handleClick()">
    <div class="header px-3 my-2 text-capitalize">
      {{ title }}
      <hr class="mt-2 mb-n3" />
    </div>
    <div class="card-body">
      <p class="card-title" v-html="shortText"></p>
      <div class="card-text">
        <div class="d-flex justify-content-between mb-1">
          <div>
            <i class="bi-geo-alt"></i> <small>{{ location }}</small>
          </div>
        </div>
        <i class="bi-calendar"></i> <small>{{ published }}</small>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CardAgenda",
  props: ["title", "slug", "shortText", "location", "published"],
  methods: {
    handleClick() {
      this.$router.push(`/agenda/${this.slug}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variable.scss";
.card {
  &:hover {
    cursor: pointer;
  }
  border: 1px solid #dfdfdf;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  .header {
    font-family: "Maven Pro", sans-serif;
    font-weight: 500;
    font-size: 1.2em;
    background-color: #fff;
  }
  .card-body {
    .card-text {
      font-weight: 400;
      color: $textColor;
    }
    h5.card-title {
      color: $textColor;
    }
  }
}
</style>
